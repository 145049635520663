//const { ethers } = require("hardhat");
const Web3 = require('web3');
const Tx = require('ethereumjs-tx').Transaction;
const Common = require('ethereumjs-common');

// Global variables
let httpProvider;
let web3;
let withdrawContract;
let tokenContract;
let isInitOK = false;

let withdrawContractAddr;
let tokenContractAddr;
let network_rpc_url;
let network;

module.exports = function withdrawFunctions() {
    // init function init(rpc_url, network, withdraw_abi, withdraw_addr, token_abi, token_addr)
    this.init = async function (rpc_url, _network, withdraw_abi, withdraw_addr, token_abi, token_addr) {
        try {
            network_rpc_url = rpc_url;
            network = _network;
            console.log("RPC = %s, network = %s", network_rpc_url, network);
            httpProvider = new Web3.providers.HttpProvider(network_rpc_url);
            web3 = new Web3(httpProvider);
            withdrawContractAddr = withdraw_addr;
            tokenContractAddr = token_addr;
            withdrawContract = new web3.eth.Contract(withdraw_abi, withdraw_addr);
            tokenContract = new web3.eth.Contract(token_abi, token_addr);
            isInitOK = true;
            //console.log("[Init] Success");
        } catch (error) {
            console.error("[Init] Fail - ", error);
            isInitOK = false;
        }
        return isInitOK;
    }

    // web3 instance
    this.web3Utils = function () {
        let web3Utils;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return null;
            }

            web3Utils = web3.utils;
        } catch (error) {
            console.error("[web3Utils] Fail - ", error);
            web3Utils = null;
        }
        return web3Utils;
    }

    // async function getContractOwner(contractAddress)
    this.getWithdrawContractOwner = async function () {
        let ownerAddr = null;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return null;
            }

            ownerAddr = await withdrawContract.methods.getOwner().call();
        } catch (error) {
            console.error("[getWithdrawContractOwner] Fail - ", error);
        }
        return ownerAddr;
    }

    // async function isAdmin(address)
    this.isAdmin = async function (address) {
        let isAdmin = false;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return false;
            }

            isAdmin = await withdrawContract.methods.isAdminOrNot(address).call();
            // if (isAdmin == false) {
            //     console.log("[isAdmin] address %s is not an admin", address);
            // } else {
            //     console.log("[isAdmin] address %s is an admin", address);
            // }
        } catch (error) {
            console.error("[isAdmin] Fail - ", error);
        }
        return isAdmin;
    }

    // async function checkAllowance(adminAddr)
    this.checkAllowance = async function (adminAddr) {
        let allowance = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            allowance = await tokenContract.methods.allowance(adminAddr, withdrawContractAddr).call();
            //console.log("[checkAllowance] Allowance = %s", allowance);
        } catch (error) {
            console.error("[checkAllowance] Fail - ", error);
            allowance = -1;
        }
        return allowance;
    }

    // async function getBalance(address)
    // Get platform token balance(ETH for Ethereum, BNB for BNB chain)
    this.getBalance = async function (address) {
        let balance = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            balance = await web3.eth.getBalance(address);
            //console.log("[getBalance] Balance of %s = %s", address, web3.utils.fromWei(balance, "ether"));
        } catch (error) {
            console.error("[getBalance] Fail - ", error);
            balance = -1;
        }
        return balance;
    }

    // async function getTokenBalance(address)
    this.getTokenBalance = async function (address) {
        let tokenBalance = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            tokenBalance = await tokenContract.methods.balanceOf(address).call();
        } catch (error) {
            console.error("[getTokenBalance] Fail - ", error);
            tokenBalance = -1;
        }
        return tokenBalance;
    }

    // async function getTokenSymbol()
    this.getTokenSymbol = async function () {
        let tokenSymbol = "";
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return "";
            }

            tokenSymbol = await tokenContract.methods.symbol().call();
        } catch (error) {
            console.error("[getTokenSymbol] Fail - ", error);
            tokenSymbol = "";
        }
        return tokenSymbol;
    }

    // async function getNonce(address)
    this.getNonce = async function (address) {
        let txCount = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            await web3.eth.getTransactionCount(address, (error, _txCount) => {
                if (error != null) {
                    throw (error);
                } else {
                    //console.log("[getNonce] txCount of %s: %s", address, _txCount);
                    txCount = _txCount;
                }
            });
        } catch (error) {
            console.error("[getNonce] Fail - ", error);
            txCount = -1;
        }
        return txCount;
    }

    // async function getGasPrice()
    this.getGasPrice = async function () {
        let gasPrice = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            gasPrice = Number(await web3.eth.getGasPrice());
        } catch (error) {
            console.error("[getGasPrice] Fail - ", error);
            gasPrice = -1;
        }
        return gasPrice;
    }

    // async function estimateGasSetAdmin(adminAddr, ownerAddr)
    this.estimateGasSetAdmin = async function (adminAddr, ownerAddr) {
        let estimatedGas = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            const _ownerAddr = await this.getWithdrawContractOwner();
            if (_ownerAddr == null) {
                throw ("Get contract owner Fail");
            } else if (_ownerAddr !== ownerAddr) {
                throw ("Contract owner mismatch");
            } else {
                estimatedGas = await withdrawContract.methods.setAdmin(adminAddr)
                    .estimateGas({ from: ownerAddr });
                //console.log("[estimateGasSetAdmin] estimated gas - %s", estimatedGas);
            }
        } catch (error) {
            console.error("[estimateGasSetAdmin] Fail - ", error);
            estimatedGas = -1;
        }
        return estimatedGas;
    }

    // async function estimateGasRemoveAdmin(adminAddr, ownerAddr)
    this.estimateGasRemoveAdmin = async function (adminAddr, ownerAddr) {
        let estimatedGas = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            const _ownerAddr = await this.getWithdrawContractOwner();
            if (_ownerAddr == null) {
                throw ("Get contract owner Fail");
            } else if (_ownerAddr !== ownerAddr) {
                throw ("Contract owner mismatch");
            } else {
                estimatedGas = await withdrawContract.methods.removeAdmin(adminAddr)
                    .estimateGas({ from: ownerAddr });
                //console.log("[estimateGasRemoveAdmin] estimated gas - %s", estimatedGas);
            }
        } catch (error) {
            console.error("[estimateGasRemoveAdmin] Fail - ", error);
            estimatedGas = -1;
        }
        return estimatedGas;
    }

    // async function estimateGasApproveToken(adminAddr)
    this.estimateGasApproveToken = async function (adminAddr) {
        let estimatedGas = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            estimatedGas = await tokenContract.methods.approve(
                withdrawContractAddr,
                '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            ).estimateGas({ from: adminAddr });
        } catch (error) {
            console.error("[estimateGasApproveToken] Fail - ", error);
            estimatedGas = -1;
        }
        return estimatedGas;
    }

    // async function estimateGasWithdrawToPlayer(adminAddr, playerAddr, amount)
    this.estimateGasWithdrawToPlayer = async function (adminAddr, playerAddr, amount) {
        if (amount === 0) {
            return 0;
        }

        let estimatedGas = -1;
        try {
            if (isInitOK === false) {
                console.error("Not Initialized");
                return -1;
            }

            estimatedGas = await withdrawContract.methods.withdrawToPlayer(playerAddr, amount).estimateGas({ from: adminAddr });
        } catch (error) {
            console.error("[estimateGasWithdrawToPlayer] Fail - ", error);
            estimatedGas = -1;
        }
        return estimatedGas;
    }

    // async function setAdmin(adminAddr, ownerAddr, ownerPK)
    this.setAdmin = async function (adminAddr, ownerAddr, ownerPK) {
        let result = false;
        if (isInitOK === false) {
            console.error("Not Initialized");
            return false;
        }

        try {
            const isAdmin = await withdrawContract.methods.isAdminOrNot(adminAddr).call();
            //console.log("isAdmin: ", isAdmin, ", type: ", typeof isAdmin);
            if (isAdmin === false) {
                console.warn("Address: ", adminAddr, " is not an admin, owner will set it");

                // Owner need to set adminAddr as an admin in WithdrawUSDT contract
                let txCount = await this.getNonce(ownerAddr);
                if (txCount === -1) throw ("Get txCount fail");

                let gasPrice = await this.getGasPrice();
                if (gasPrice === -1) throw ("Get gas price fail");

                let gas = await this.estimateGasSetAdmin(adminAddr, ownerAddr);
                if (gas === -1) throw ("Estimate gas fail");

                // Prepare tx data
                const txData = withdrawContract.methods.setAdmin(adminAddr).encodeABI();
                const txObject = {
                    nonce: web3.utils.toHex(txCount),
                    to: withdrawContractAddr,
                    //from: adminAddr,
                    //value: web3.utils.toHex(web3.utils.toWei('0.1', 'ether')),
                    gasLimit: web3.utils.toHex(gas + parseInt(gas / 2)),
                    gasPrice: web3.utils.toHex(gasPrice + parseInt(gasPrice / 10)),
                    data: txData
                };
                let common;
                if (network === 'rinkeby')
                    common = Common.default.forCustomChain('mainnet', { name: 'eth', networkId: 4, chainId: 4 }, 'petersburg');
                else if (network === 'bscTestnet')
                    common = Common.default.forCustomChain('mainnet', { name: 'bnb', networkId: 97, chainId: 97 }, 'petersburg');
                else if (network === 'bsc')
                    common = Common.default.forCustomChain('mainnet', { name: 'bnb', networkId: 56, chainId: 56 }, 'petersburg');
                //const tx = new Tx(txObject, { 'chain': 'rinkeby' });
                const tx = new Tx(txObject, { common });
                tx.sign(Buffer.from(ownerPK, 'hex'));
                const raw = '0x' + tx.serialize().toString('hex');
                //console.log("raw: ", raw);
                await web3.eth.sendSignedTransaction(raw)
                    .on('transactionHash', (txHash) => {
                        console.log("txHash: %s", txHash);
                    })
                    .on('receipt', (receipt) => {
                        //console.log("receipt(setAdmin): ", receipt, "\n");
                        console.log("receipt(setAdmin) received");
                    })
                    .on('error', (error) => {
                        //console.log("error: ", error);
                        throw (error);
                    });
                result = true;
            } else {
                console.log("[setAdmin] Address %s is already an admin", adminAddr);
                result = true;
            }
        } catch (error) {
            console.error("[setAdmin] Fail - %s", error);
            result = false;
        }
        return result;
    }

    // async function removeAdmin(adminAddr, ownerAddr, ownerPK)
    this.removeAdmin = async function (adminAddr, ownerAddr, ownerPK) {
        let result = false;
        if (isInitOK === false) {
            console.error("Not Initialized");
            return false;
        }

        try {
            const isAdmin = await withdrawContract.methods.isAdminOrNot(adminAddr).call();
            //console.log("isAdmin: ", isAdmin, ", type: ", typeof isAdmin);
            if (isAdmin === true) {
                console.warn("Address: ", adminAddr, " is already an admin, owner will remove it");

                // If owner want to remove adminAddr from an admin in WithdrawUSDT contract
                let txCount = await this.getNonce(ownerAddr);
                if (txCount === -1) throw ("Get txCount fail");

                let gasPrice = await this.getGasPrice();
                if (gasPrice === -1) throw ("Get gas price fail");

                let gas = await this.estimateGasRemoveAdmin(adminAddr, ownerAddr);
                if (gas === -1) throw ("Estimate gas fail");

                // Prepare tx data
                const txData = withdrawContract.methods.removeAdmin(adminAddr).encodeABI();
                const txObject = {
                    nonce: web3.utils.toHex(txCount),
                    to: withdrawContractAddr,
                    //from: adminAddr,
                    //value: web3.utils.toHex(web3.utils.toWei('0.1', 'ether')),
                    gasLimit: web3.utils.toHex(gas + parseInt(gas / 2)),
                    gasPrice: web3.utils.toHex(gasPrice + parseInt(gasPrice / 10)),
                    data: txData
                };
                let common;
                if (network === 'rinkeby')
                    common = Common.default.forCustomChain('rinkeby', { name: 'eth', networkId: 4, chainId: 4 }, 'petersburg');
                else if (network === 'bscTestnet')
                    common = Common.default.forCustomChain('mainnet', { name: 'bnb', networkId: 97, chainId: 97 }, 'petersburg');
                else if (network === 'bsc')
                    common = Common.default.forCustomChain('mainnet', { name: 'bnb', networkId: 56, chainId: 56 }, 'petersburg');
                //const tx = new Tx(txObject, { 'chain': 'rinkeby' });
                const tx = new Tx(txObject, { common });
                tx.sign(Buffer.from(ownerPK, 'hex'));
                const raw = '0x' + tx.serialize().toString('hex');
                //console.log("raw: ", raw);
                await web3.eth.sendSignedTransaction(raw)
                    .on('transactionHash', (txHash) => {
                        console.log("txHash: %s", txHash);
                    })
                    .on('receipt', (receipt) => {
                        //console.log("receipt(removeAdmin): ", receipt, "\n");
                        console.log("receipt(removeAdmin) received");
                    })
                    .on('error', (error) => {
                        //console.log("error: ", error);
                        throw (error);
                    });
                result = true;
            } else {
                console.log("[removeAdmin] Address %s is not an admin", adminAddr);
                result = true;
            }
        } catch (error) {
            console.error("[removeAdmin] Fail - %s", error);
            result = false;
        }
        return result;
    }

    // async function approveToken(adminAddr, adminPK)
    this.approveToken = async function (adminAddr, adminPK) {
        let retTxHash = "0x0";
        if (isInitOK === false) {
            console.error("Not Initialized");
            return "0x0";
        }

        try {
            // adminAddr want to approve token spending for withdraw contract
            let txCount = await this.getNonce(adminAddr);
            if (txCount === -1) throw ("Get txCount fail");

            let gasPrice = await this.getGasPrice();
            if (gasPrice === -1) throw ("Get gas price fail");

            let gas = await this.estimateGasApproveToken(adminAddr, withdrawContractAddr);
            if (gas === -1) throw ("Estimate gas fail");

            // Prepare tx data
            const txData = tokenContract.methods.approve(
                withdrawContractAddr,
                '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            ).encodeABI();
            const txObject = {
                nonce: web3.utils.toHex(txCount),
                to: tokenContractAddr,
                //from: adminAddr,
                //value: web3.utils.toHex(web3.utils.toWei('0.1', 'ether')),
                gasLimit: web3.utils.toHex(gas + parseInt(gas / 2)),
                gasPrice: web3.utils.toHex(gasPrice + parseInt(gasPrice / 10)),
                data: txData
            };
            let common;
            if (network === 'rinkeby')
                common = Common.default.forCustomChain('rinkeby', { name: 'eth', networkId: 4, chainId: 4 }, 'petersburg');
            else if (network === 'bscTestnet')
                common = Common.default.forCustomChain('mainnet', { name: 'bnb', networkId: 97, chainId: 97 }, 'petersburg');
            else if (network === 'bsc')
                common = Common.default.forCustomChain('mainnet', { name: 'bnb', networkId: 56, chainId: 56 }, 'petersburg');
            //const tx = new Tx(txObject, { 'chain': 'rinkeby' });
            const tx = new Tx(txObject, { common });
            tx.sign(Buffer.from(adminPK, 'hex'));
            const raw = '0x' + tx.serialize().toString('hex');
            //console.log("raw: ", raw);
            await web3.eth.sendSignedTransaction(raw)
                .on('transactionHash', (txHash) => {
                    retTxHash = txHash;
                    console.log("txHash: %s", txHash);
                })
                .on('receipt', (receipt) => {
                    //console.log("receipt(approveToken): ", receipt, "\n");
                    retTxHash = JSON.parse(JSON.stringify(receipt))["transactionHash"];
                    console.log("transactionHash: %s", retTxHash);
                    // console.log("receipt(approveToken) received");
                })
                .on('error', (error) => {
                    //console.log("error: ", error);
                    throw (error);
                });
        } catch (error) {
            console.error("[approveToken] Fail - %s", error);
        }

        return retTxHash;
    }

    // async function withdrawToPlayer(adminAddr, adminPK, playerAddr, amount)
    this.withdrawToPlayer = async function (adminAddr, adminPK, playerAddr, amount) {
        let retTxHash = "0x0";
        if (isInitOK === false) {
            console.error("Not Initialized");
            return "0x0";
        }

        try {
            // adminAddr want to approve token spending for withdraw contract
            let txCount = await this.getNonce(adminAddr);
            if (txCount === -1) throw ("Get txCount fail");

            let gasPrice = await this.getGasPrice();
            if (gasPrice === -1) throw ("Get gas price fail");

            let gas = await this.estimateGasWithdrawToPlayer(adminAddr, playerAddr, amount);
            if (gas === -1) throw ("Estimate gas fail");

            // Prepare tx data
            const txData = withdrawContract.methods.withdrawToPlayer(playerAddr, amount).encodeABI();
            const txObject = {
                nonce: web3.utils.toHex(txCount),
                to: withdrawContractAddr,
                //from: adminAddr,
                //value: web3.utils.toHex(web3.utils.toWei('0.1', 'ether')),
                gasLimit: web3.utils.toHex(gas + parseInt(gas / 2)),
                gasPrice: web3.utils.toHex(gasPrice + parseInt(gasPrice / 10)),
                data: txData
            };
            let common;
            if (network === 'rinkeby')
                common = Common.default.forCustomChain('rinkeby', { name: 'eth', networkId: 4, chainId: 4 }, 'petersburg');
            else if (network === 'bscTestnet')
                common = Common.default.forCustomChain('mainnet', { name: 'bnb', networkId: 97, chainId: 97 }, 'petersburg');
            else if (network === 'bsc')
                common = Common.default.forCustomChain('mainnet', { name: 'bnb', networkId: 56, chainId: 56 }, 'petersburg');
            //const tx = new Tx(txObject, { 'chain': 'rinkeby' });
            const tx = new Tx(txObject, { common });
            tx.sign(Buffer.from(adminPK, 'hex'));
            const raw = '0x' + tx.serialize().toString('hex');
            //console.log("raw: ", raw);
            await web3.eth.sendSignedTransaction(raw)
                .on('transactionHash', (txHash) => {
                    retTxHash = txHash;
                    console.log("txHash: %s", txHash);
                })
                .on('receipt', (receipt) => {
                    //console.log("receipt(withdrawToPlayer): ", receipt, "\n");
                    retTxHash = JSON.parse(JSON.stringify(receipt))["transactionHash"];
                    console.log("transactionHash: %s", retTxHash);
                    // console.log("receipt(withdrawToPlayer) received");
                })
                .on('error', (error) => {
                    //console.log("error: ", error);
                    throw (error);
                });
        } catch (error) {
            console.error("[withdrawToPlayer] Fail - %s", error);
        }

        return retTxHash;
    }
}
